<template>
  <v-card tile :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color=""
            size="x-small"
            :to="{ name: 'servicios.rs_refounds' }"
          />
          <span
            class="text-h6 ml-1"
            v-text="`${data ? `${data.folio} | ` : ''}${$route.meta.title}`"
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip v-if="data" left>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                x-small
                v-on="on"
                class="ml-1"
                :color="
                  data.rs_comment_detail_pending ? 'warning' : 'grey darken-1'
                "
                @click.prevent="rsCommentDetailDialog()"
                :loading="loading"
              >
                <v-icon> mdi-email </v-icon>
              </v-btn>
            </template>
            <span v-text="'Comentarios | Observaciones'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row v-if="!loading">
        <!-- <v-col cols="12" v-if="data.insurance.judgment && !data.insured_id">
          <v-alert type="warning" dense>
            <v-row align="center">
              <v-col class="text-center">
                El asegurado no ha sido registrado en la base de datos
              </v-col>
              <v-col class="shrink">
                <v-btn
                  small
                  v-text="'Asignar'"
                  @click.prevent="insuredDialog"
                />
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
        -->
        <v-col
          cols="12"
          v-if="
            data.rs_refound_rejected &&
            (data.rs_refound_rejected.rs_refound_rejected_type_id == 1 ||
              (data.rs_refound_rejected.rs_refound_rejected_type_id == 2 &&
                !data.rs_refound_rejected.response))
          "
        >
          <v-alert
            :type="
              data.rs_refound_rejected.rs_refound_rejected_type_id == 1
                ? 'error'
                : 'warning'
            "
            dense
          >
            <h5 v-text="data.rs_refound_rejected.rs_refound_rejected_type" />
            <span v-text="data.rs_refound_rejected.observation" />
          </v-alert>
        </v-col>
        <v-col cols="12" v-if="data.discharged">
          <v-alert type="success" dense>
            <div class="text-center">
              La solicitud fue terminada el {{ data.discharged }}
            </div>
          </v-alert>
        </v-col>
        <v-col cols="12" v-if="!data.insured_id">
          <v-alert type="warning" dense>
            <v-row align="center">
              <v-col class="grow">
                El asegurado no se encuentra en sistema
              </v-col>
              <v-col class="shrink">
                <v-btn small v-text="'Buscar'" @click.prevent="insuredDialog" />
              </v-col>
            </v-row>
          </v-alert>
        </v-col>

        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'GENERAL'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="6">
                  <ViewData label="Usuario" :value="data.user.email" />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData label="Aseguradora" :value="data.insurance.name" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'ASEGURADO'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items>
                <v-btn
                  v-if="data.insured_id"
                  dark
                  small
                  @click="sinisterRepDialog"
                >
                  VER SINIESTRALIDAD
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    :class="`text-caption${
                      !data.insured_id ? ' orange--text text--darken-3' : ''
                    }`"
                    label="ID SM / Póliza"
                    :value="data.enrollment"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    :class="`text-caption${
                      !data.insured_id ? ' orange--text text--darken-3' : ''
                    }`"
                    label="Nombre"
                    :value="data.name"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    :class="`text-caption${
                      !data.insured_id ? ' orange--text text--darken-3' : ''
                    }`"
                    label="A. Paterno"
                    :value="data.first_surname"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    :class="`text-caption${
                      !data.insured_id ? ' orange--text text--darken-3' : ''
                    }`"
                    label="A. Materno"
                    :value="data.second_surname"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    :class="`text-caption${
                      !data.insured_id ? ' orange--text text--darken-3' : ''
                    }`"
                    label="F. nacimiento"
                    :value="data.insured ? data.insured.birthday : '-'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    :class="`text-caption${
                      !data.insured_id ? ' orange--text text--darken-3' : ''
                    }`"
                    label="Vigencia"
                    :value="data.insured ? data.insured.validity : '-'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    :class="`text-caption${
                      !data.insured_id ? ' orange--text text--darken-3' : ''
                    }`"
                    label="Nombre de oficina"
                    :value="data.office_address"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    :class="`text-caption${
                      !data.insured_id ? ' orange--text text--darken-3' : ''
                    }`"
                    label="Contratante"
                    :value="data.contractor"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'SOLICITUD'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Tipo" :value="data.rs_type.type" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="C. electrónico" :value="data.email" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="C. electrónico alt." :value="data.email2" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Teléfono" :value="data.phone" />
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  md="3"
                  v-if="data.rs_type_id == 1 || data.rs_type_id == 4"
                >
                  <ViewData label="Folio de envio" :value="data.send_folio" />
                </v-col>
                <v-col cols="12" xs="12" md="3" v-if="data.rs_type_id == 1">
                  <ViewData
                    label="Consulta médica telefónica"
                    :value="data.phone_medical ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3" v-if="data.phone_medical">
                  <ViewData
                    label="Estado"
                    :value="data.phone_medical_entity.entity"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3" v-if="data.phone_medical">
                  <ViewData
                    label="Municipio"
                    :value="data.phone_medical_municipality.municipality"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3" v-if="data.phone_medical">
                  <ViewData label="Fecha" :value="data.phone_medical_date" />
                </v-col>
                <v-col cols="12" xs="12" md="3" v-if="data.phone_medical">
                  <ViewData label="Hora" :value="data.phone_medical_time" />
                </v-col>
                <v-col cols="12">
                  <ViewData
                    label="Detalle de la solicitud"
                    :value="data.detail"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'DOCUMENTOS'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" v-for="(item, i) in data.documents" :key="i">
                  <v-row>
                    <v-col cols="12" xs="12" md="11">
                      <ViewData
                        :label="'Descripción'"
                        :value="item.description"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="1">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="ml-1"
                            v-on="on"
                            fab
                            x-small
                            color="warning"
                            :href="url_documents + '/rs_documents/' + item.url"
                            target="_blank"
                          >
                            <v-icon v-text="'mdi-download'" />
                          </v-btn>
                        </template>
                        <span v-text="'Descargar'" />
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12">
                      <v-divider />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col v-if="data.rs_refound_bills.length > 0" cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'FACTURAS'" />
              </v-toolbar-title>
            </v-toolbar>
            <v-spacer />
            <v-card-text>
              <v-row
                v-for="(rs_refound_bill, i) in data.rs_refound_bills"
                :key="i"
                dense
              >
                <v-col cols="12" class="grey darken-3 white--text">
                  <v-row dense>
                    <v-col cols="8">
                      <h4
                        v-text="
                          'FACTURA (' +
                          (i + 1) +
                          ') | ' +
                          rs_refound_bill.xml_folio +
                          (rs_refound_bill.revision_user_id
                            ? ''
                            : ' | SEG. MÉDICO PEND.')
                        "
                        class="py-1 px-1"
                      />
                    </v-col>
                    <v-col cols="4" class="text-right mt-1">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            text
                            icon
                            x-small
                            color="white"
                            class="mr-1"
                            :href="
                              url_documents +
                              '/rs_refound_bills/' +
                              rs_refound_bill.url_pdf
                            "
                            target="_blank"
                          >
                            <v-icon> mdi-file-document </v-icon>
                          </v-btn>
                        </template>
                        <span v-text="'Ver PDF'" />
                      </v-tooltip>
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            text
                            icon
                            x-small
                            color="white"
                            class="mr-1"
                            :href="
                              url_documents +
                              '/rs_refound_bills/' +
                              rs_refound_bill.url_xml
                            "
                            target="_blank"
                          >
                            <v-icon> mdi-xml </v-icon>
                          </v-btn>
                        </template>
                        <span v-text="'Ver XML'" />
                      </v-tooltip>
                      <v-tooltip v-if="login.role_id == 1" left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            text
                            icon
                            x-small
                            color="white"
                            class="mr-1"
                            @click.prevent="
                              rsRefoundBillDelete(rs_refound_bill.id)
                            "
                          >
                            <v-icon> mdi-cancel </v-icon>
                          </v-btn>
                        </template>
                        <span v-text="'Eliminar'" />
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  v-if="rs_refound_bill.rs_refound_bill_items"
                  cols="12"
                  xs="12"
                  md="4"
                >
                  <ViewData
                    label="Emisor"
                    :value="
                      rs_refound_bill.emisor_code_tax +
                      ' | ' +
                      rs_refound_bill.emisor_legal_name
                    "
                  />
                </v-col>
                <v-col
                  v-if="rs_refound_bill.rs_refound_bill_items"
                  cols="12"
                  xs="12"
                  md="4"
                >
                  <ViewData
                    :class="rs_refound_bill.code_tax_valid ? '' : 'red--text'"
                    label="Receptor"
                    :value="
                      rs_refound_bill.receptor_code_tax +
                      ' | ' +
                      rs_refound_bill.receptor_legal_name
                    "
                  />
                </v-col>
                <v-col
                  v-if="rs_refound_bill.rs_refound_bill_items"
                  cols="12"
                  xs="12"
                  md="4"
                >
                  <ViewData label="Fecha" :value="rs_refound_bill.xml_date" />
                </v-col>
                <v-col v-if="rs_refound_bill.rs_refound_bill_items" cols="12">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th width="40px">#</th>
                          <th>Descripción</th>
                          <th width="150px">Cantidad</th>
                          <th width="150px">Valor unitario</th>
                          <th width="150px">Importe</th>
                          <th width="150px">IVA</th>
                          <th width="150px">Monto reclamado</th>
                          <th width="150px">Gastos cubiertos</th>
                          <th width="150px">Gastos no cubiertos</th>
                          <th width="200px">Observación</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(
                            rs_refound_bill_item, j
                          ) in rs_refound_bill.rs_refound_bill_items"
                          :key="j"
                        >
                          <td v-text="j + 1" />
                          <td v-text="rs_refound_bill_item.item" />
                          <td v-text="rs_refound_bill_item.quantity" />
                          <td
                            v-text="numberFormat(rs_refound_bill_item.amount)"
                          />
                          <td
                            v-text="
                              numberFormat(rs_refound_bill_item.total_amount)
                            "
                          />
                          <td
                            v-text="
                              rs_refound_bill_item.tax +
                              '% | ' +
                              numberFormat(rs_refound_bill_item.tax_amount)
                            "
                          />
                          <td>
                            <b
                              v-text="
                                numberFormat(
                                  rs_refound_bill_item.total_tax_amount
                                )
                              "
                            />
                          </td>
                          <td>
                            <span
                              v-text="
                                numberFormat(
                                  rs_refound_bill_item.refound_amount
                                )
                              "
                            />
                          </td>
                          <td>
                            <b
                              v-text="
                                numberFormat(rs_refound_bill_item.no_refound)
                              "
                            />
                          </td>
                          <td
                            v-text="
                              rs_refound_bill_item.rs_refound_item_type
                                ? rs_refound_bill_item.rs_refound_item_type
                                    .rs_refound_item_type
                                : 'N/A'
                            "
                          />
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <RsServices
            :data="services"
            :title="`SERVICIOS (${services.length})`"
          />
        </v-col>

        <v-col cols="12" v-if="data.accident !== null && data.initial !== null">
          <RsServices
            :data="services_medical_revision"
            :title="`SERVICIOS SEG. MÉDICO (${services_medical_revision.length})`"
          />
        </v-col>

        <v-col cols="12" v-if="data.rs_type_id != 4">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span
                  class="text-caption"
                  v-text="
                    'SEGUIMIENTO MÉDICO' +
                    (data.accident == null && data.initial == null
                      ? ' (NO REG.)'
                      : '')
                  "
                />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-spacer />
            <v-card-text v-if="data.accident !== null && data.initial !== null">
              <v-row dense>
                <v-col cols="12">
                  <span
                    class="text-caption font-weight-bold"
                    v-text="'Diagnostico definitivo (ICD)'"
                  />
                  <br />
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th v-text="'#'" />
                          <th v-text="'Código'" />
                          <th v-text="'Descripción'" />
                          <th v-text="''" />
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(v, i) in data.icds"
                          :key="i"
                          v-if="v.active"
                        >
                          <td v-text="`${i + 1}`" />
                          <td v-text="v.code" />
                          <td v-text="v.icd" />
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col cols="12" />
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    :label="data.accident ? 'Accidente' : 'Enfermedad'"
                    value=""
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    :label="data.initial ? 'Inicial' : 'Complemento'"
                    value=""
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Prexistente / Riesgo"
                    :value="data.preexisting ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12">
                  <ViewData label="Comentario" :value="data.comment" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'REGISTRO'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Creación"
                    :value="!loading && data.created_by.email"
                    :subvalue="data.created_at"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="U. actualización"
                    :value="!loading && data.updated_by.email"
                    :subvalue="data.updated_at"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <BtnCircle
            v-if="login.permissions.rss.delete && !data.discharged"
            tLabel="Cancelar solicitud de servicio"
            tPosition="top"
            icon="mdi-sticker-remove-outline"
            color="error"
            size="x-small"
            :click="desactivate"
            :loading="loading"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <!-- DIALOGS-->
    <v-dialog v-model="insured_dialog" scrollable persistent max-width="1000">
      <v-card
        tile
        :loading="insured_dialog_loading"
        :disabled="insured_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="'Asignar asegurado'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="insured_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" />
            <v-col cols="12" v-if="!insured">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'ASEGURADO'" />
                    <small
                      class="text-caption"
                      v-text="' | Pulsa enter sobre un campo para buscar'"
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="info"
                          icon
                          @click.prevent="insuredSearch"
                        >
                          <v-icon v-text="'mdi-account-search'" />
                        </v-btn>
                      </template>
                      <span v-text="'Buscar'" />
                    </v-tooltip>
                  </v-toolbar-items>
                </v-toolbar>
                <v-spacer />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="3">
                      <v-text-field
                        label="ID"
                        v-model="insured_search.enrollment"
                        type="text"
                        v-on:keyup.enter="insuredSearch"
                        dense
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <v-text-field
                        label="Nombre"
                        v-model="insured_search.name"
                        type="text"
                        v-on:keyup.enter="insuredSearch"
                        dense
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <v-text-field
                        label="A. Paterno"
                        v-model="insured_search.first_surname"
                        type="text"
                        v-on:keyup.enter="insuredSearch"
                        dense
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <v-text-field
                        label="A. Materno"
                        v-model="insured_search.second_surname"
                        type="text"
                        v-on:keyup.enter="insuredSearch"
                        dense
                      />
                    </v-col>
                    <v-col cols="12" v-if="insured_search_fail">
                      <v-alert type="warning" dense>
                        Asegurado no encontrado en sistema
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" v-if="insured_table">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span
                      class="text-caption"
                      v-text="'RESULTADO DE BUSQUEDA'"
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items> </v-toolbar-items>
                </v-toolbar>
                <v-spacer />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <td v-text="'ID'" />
                              <td v-text="'Asegurado'" />
                              <td v-text="''" />
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(v, i) in insureds" :key="i">
                              <td v-text="v.enrollment" />
                              <td
                                v-text="
                                  `${v.name} ${v.first_surname} ${v.second_surname}`
                                "
                              />
                              <td>
                                <v-tooltip left>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      icon
                                      small
                                      color="warning"
                                      @click="insuredData(v.id)"
                                    >
                                      <v-icon v-text="'mdi-check'" />
                                    </v-btn>
                                  </template>
                                  <span v-text="'Seleccionar'" />
                                </v-tooltip>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" v-if="insured && insured.insured_id">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'ASEGURADO'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="warning"
                          icon
                          @click.prevent="insuredRemove"
                        >
                          <v-icon v-text="'mdi-account-reactivate'" />
                        </v-btn>
                      </template>
                      <span v-text="'Volver a buscar'" />
                    </v-tooltip>
                  </v-toolbar-items>
                </v-toolbar>
                <v-spacer />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData label="ID" :value="insured.enrollment" />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData label="Nombre" :value="insured.name" />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData
                        label="A. Paterno"
                        :value="insured.first_surname"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData
                        label="A. Materno"
                        :value="insured.second_surname"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData label="Póliza" :value="insured.policy" />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <ViewData
                        label="Núm. de asegurado"
                        :value="insured.credential"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                      <ViewData
                        label="Contratante"
                        :value="insured.contractor"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              v-if="insured && insured.insured_id"
              cols="12"
              xs="12"
              md="12"
              class="text-right"
            >
              <BtnCircle
                tLabel="Editar datos del asegurado"
                tPosition="top"
                icon="mdi-pencil"
                color="info"
                size="x-small"
                :click="insuredSubmit"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="rs_comment_detail_dialog"
      scrollable
      persistent
      max-width="600"
    >
      <v-card
        tile
        :disabled="rs_comment_detail_dialog_loading"
        :loading="rs_comment_detail_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> COMENTARIOS | OBSERVACIONES </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="rs_comment_detail_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-form
                v-on:submit.prevent
                ref="rs_comment_detail_form"
                lazy-validation
              >
                <v-row dense>
                  <v-col cols="12" class="mt-3" />
                  <v-col cols="12">
                    <v-card tile>
                      <v-toolbar elevation="1" dense>
                        <v-toolbar-title>
                          <span class="text-caption" v-text="'DETALLE'" />
                        </v-toolbar-title>
                        <v-spacer />
                        <v-toolbar-items />
                      </v-toolbar>
                      <v-spacer />
                      <v-card-text>
                        <v-row dense>
                          <v-col
                            cols="12"
                            v-for="(rs_comment_detail, i) in rs_comment_details"
                            :key="i"
                          >
                            <v-row dense>
                              <v-col cols="11">
                                <span
                                  class="text-caption font-weight-bold"
                                  v-text="rs_comment_detail.created_at"
                                />
                                <v-icon
                                  small
                                  :color="
                                    rs_comment_detail.response
                                      ? 'info'
                                      : 'warning'
                                  "
                                  right
                                >
                                  mdi-email-arrow-{{
                                    rs_comment_detail.response
                                      ? "left"
                                      : "right"
                                  }}
                                </v-icon>
                                <br />
                                <span
                                  class="text-description"
                                  v-text="rs_comment_detail.detail"
                                />
                              </v-col>
                              <v-col cols="1">
                                <v-btn
                                  v-if="rs_comment_detail.document_url"
                                  fab
                                  x-small
                                  class="ml-1"
                                  color="warning"
                                  :href="
                                    url_documents +
                                    '/rs_comment_details/' +
                                    rs_comment_detail.document_url
                                  "
                                  target="_blank"
                                >
                                  <v-icon> mdi-download </v-icon>
                                </v-btn>
                              </v-col>
                              <v-col cols="12">
                                <v-divider class="pb-1" />
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <v-row
                          v-if="rs_comment_detail_response && !data.discharged"
                          cols="12"
                          dense
                        >
                          <v-col cols="12">
                            <v-textarea
                              label="Comentario"
                              rows="3"
                              v-model="rs_comment_detail.detail"
                              dense
                              :rules="rules.required"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-file-input
                              label="Archivo digital (PDF)"
                              v-model="rs_comment_detail.document_file_0"
                              accept="image/,.pdf"
                              :rules="rules.fileLmt"
                              show-size
                              prepend-icon=""
                              append-icon="$file"
                              dense
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-form>
              <v-row
                v-if="rs_comment_detail_response && !data.discharged"
                dense
                class="mt-4"
              >
                <v-col cols="12">
                  <v-btn
                    block
                    color="info"
                    @click.prevent="rsCommentDetailHandle"
                  >
                    Enviar
                    <v-icon right> mdi-send </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- <v-dialog
      v-model="discharge_dialog"
      scrollable
      persistent
      max-width="1000px"
    >
      <v-card
        tile
        :loading="discharge_dialog_loading"
        :disabled="discharge_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="'Ingreso Hospitalario | Alta'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="discharge_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-form v-on:submit.prevent ref="discharge_form" lazy-validation>
            <v-row>
              <v-col cols="12" />
              <v-col cols="12">
                <v-card tile>
                  <v-toolbar elevation="1" dense>
                    <v-toolbar-title>
                      <span class="text-caption" v-text="'DATOS'" />
                    </v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items> </v-toolbar-items>
                  </v-toolbar>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="6">
                        <DatePicker
                          label="Fecha de alta*"
                          :model.sync="discharge.date"
                          :rules="rules.required"
                          clean
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          label="Hora de alta*"
                          v-model="discharge.time"
                          :rules="rules.required"
                          type="time"
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" class="text-right">
                <BtnCircle
                  tLabel="Aceptar"
                  tPosition="top"
                  icon="mdi-check"
                  color="success"
                  size="x-small"
                  :click="dischargedSubmit"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <v-dialog v-model="sinister_rep_dlg" scrollable persistent max-width="1800">
      <v-card
        tile
        :disabled="sinister_rep_dlg_ldg"
        :loading="sinister_rep_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> SINIESTRALIDAD </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="sinister_rep_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="sinister_rep">
          <v-row dense>
            <v-col cols="12" />
            <v-col cols="12">
              <v-divider />
            </v-col>
            <v-col cols="12">
              <h4 v-text="'SINIESTRALIDAD'" />
            </v-col>
          </v-row>
          <v-row dense v-for="(icd, i) in sinister_rep.list" :key="i">
            <v-col cols="12">
              <span class="text-caption" v-text="icd.code + ' | ' + icd.icd" />
            </v-col>
            <v-col cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-text="'#'" />
                      <th v-text="'Tipo'" />
                      <th v-text="'Proveedor'" />
                      <th v-text="'T. Prov. | Espec.'" />
                      <th v-text="'Folio'" />
                      <th v-text="'Fecha'" />
                      <th v-text="'Padecimiento'" />
                      <th v-text="'Siniestro'" />
                      <th v-text="'Núm. Siniestro'" />
                      <th v-text="'Deducible'" />
                      <th v-text="'Coaseguro %'" />
                      <th v-text="'Facturado'" />
                      <th v-text="'Monto'" />
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in icd.items" :key="i">
                      <td v-text="`${i + 1}`" />
                      <td v-text="item.type" />
                      <td v-text="item.provider" />
                      <td v-text="item.provider_type" />
                      <td v-text="item.folio" />
                      <td v-text="item.date" />
                      <td v-text="item.accident ? 'ACCIDENTE' : 'ENFERMEDAD'" />
                      <td
                        v-text="item.initial ? 'INICIAL' : 'COMPLEMENTARIO'"
                      />
                      <td v-text="item.sinister_number" />
                      <td v-text="numberFormat(item.deducible_amount)" />
                      <td v-text="item.coinsurance" />
                      <td>
                        <v-icon small :color="item.bill ? 'success' : ''">
                          mdi-{{ item.bill ? "check" : "close" }}
                        </v-icon>
                      </td>
                      <td v-text="numberFormat(item.amount)" />
                      <td>
                        <v-tooltip v-if="item.comment" left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              fab
                              dark
                              x-small
                              class="ml-1"
                              color="info"
                              @click="commentDialog(item)"
                            >
                              <v-icon> mdi-message-draw </v-icon>
                            </v-btn>
                          </template>
                          <span v-text="'Seg. médico comentario'" />
                        </v-tooltip>
                      </td>
                    </tr>
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td>
                        <b v-text="numberFormat(icd.amount)" />
                      </td>
                      <td />
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="comment_dlg" scrollable persistent max-width="600px">
      <v-card v-if="comment" tile>
        <v-toolbar dark dense>
          <v-toolbar-title> FOLIO {{ comment.folio }}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="comment_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-spacer />
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'SEG. MÉDICO COMENTARIO'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <p v-text="comment.comment" />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
  URL_DOCUMENTS,
  toFormData,
} from "../../control";
import { show, destroy, storeUpdate } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import ViewData from "../../components/ViewData.vue";
import DatePicker from "../../components/DatePicker.vue";
import RsServices from "../../components/RsServices.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    ViewData,
    DatePicker,
    RsServices,
    FaqDlg,
  },
  data() {
    return {
      page_route: "servicios",
      rules: rules(),
      type: null,
      type_name: "",
      login: this.$store.getters.getLogin,
      id: this.$route.params.id ? parseInt(this.$route.params.id) : null,
      loading: true,
      data: null,
      insured_search: {},
      insured: null,
      insured_dialog: false,
      insured_dialog_loading: false,
      insured_search_fail: false,
      insureds: [],
      insured_table: false,
      url_documents: URL_DOCUMENTS,
      discharge_dialog: false,
      discharge_dialog_loading: false,
      discharge: {},
      services: null,
      services_medical_revision: null,
      rs_comment_detail: null,
      rs_comment_details: [],
      rs_comment_detail_response: false,
      rs_comment_detail_dialog: false,
      rs_comment_detail_dialog_loading: false,
      sinister_rep: null,
      sinister_rep_dlg_ldg: false,
      sinister_rep_dlg: false,
      comment: null,
      comment_dlg: false,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    getData() {
      this.loading = true;

      Axios.get(
        URL_API + `/rss/${this.id}/3`,
        headersToken(this.login.token)
      ).then((response) => {
        this.data = response.data.data;

        this.services = [];
        this.services_medical_revision = [];

        for (let service of this.data.services) {
          if (service.active) {
            if (service.medical_revision) {
              this.services_medical_revision.push(service);
            } else {
              this.services.push(service);
            }
          }
        }

        this.loading = false;
      });
    },
    insuredDialog() {
      this.insured_search = {
        enrollment: "",
        name: "",
        first_surname: "",
        second_surname: "",
        insurance_id: null,
      };

      this.insureds = [];
      this.insured_table = false;
      this.insuredRemove();
      this.insured_dialog = true;
    },
    insuredSearch() {
      if (
        this.insured_search.enrollment != "" ||
        this.insured_search.name != "" ||
        this.insured_search.first_surname != "" ||
        this.insured_search.second_surname != ""
      ) {
        this.insured_dialog_loading = true;
        this.insured_search_fail = false;

        this.insured_search.insurance_id = this.data.insurance_id;

        Axios.post(
          URL_API + "/insureds/search",
          this.insured_search,
          headersToken(this.login.token)
        ).then((response) => {
          this.insureds = response.data.data;

          if (this.insureds.length === 1) {
            this.insuredData(this.insureds[0].id);
          } else {
            if (this.insureds.length === 0) {
              this.insured_search_fail = true;
            } else {
              this.insured_table = true;
            }
          }

          this.insured_dialog_loading = false;
        });
      } else {
        this.$swal.fire(
          msgAlert(
            "warning",
            "Utiliza al menos un campo para la búsqueda de asegurado"
          )
        );
      }
    },
    insuredData(id) {
      this.insured_dialog_loading = true;

      show("insureds", this.login.token, id).then((response) => {
        this.insured = {
          insured_id: response.id,
          enrollment: response.enrollment,
          name: response.name,
          first_surname: response.first_surname,
          second_surname: response.second_surname,
          policy: response.policy,
          credential: response.credential,
          credential: response.credential,
          office_address: response.office_address,
          contractor: response.contractor.name,
          contractor_express_letter: response.contractor.express_letter,
          policyholder: this.data.policyholder,
          dependent_name: this.data.dependent_name,
          dependent_first_surname: this.data.dependent_first_surname,
          dependent_second_surname: this.data.dependent_second_surname,
          updated_by_id: this.login.id,
        };

        this.insured_table = false;
        this.insured_dialog_loading = false;
      });
    },
    insuredRemove() {
      this.insured = null;
    },
    insuredSubmit() {
      this.$swal
        .fire(msgConfirm(`¿Confirma editar datos del asegurado?`))
        .then((response) => {
          if (response.isConfirmed) {
            this.insured_dialog_loading = true;

            let rss = Object.assign({}, this.data);
            rss.enrollment = this.insured.enrollment;
            rss.name = this.insured.name;
            rss.first_surname = this.insured.first_surname;
            rss.second_surname = this.insured.second_surname;
            rss.office_address = this.insured.office_address;
            rss.contractor = this.insured.contractor;
            rss.insured_id = this.insured.insured_id;

            storeUpdate(
              "rss",
              this.login.token,
              this.id,
              rss,
              this.login.id
            ).then((response) => {
              this.$swal.fire(
                msgAlert(
                  response.success ? "success" : "error",
                  response.message
                )
              );

              if (response.success) {
                this.insured_dialog = false;
                this.getData();
              } else {
                console.log(response.message);
              }
            });

            this.insured_dialog_loading = false;
          }
        });
    },
    desactivate() {
      this.$swal
        .fire(
          msgConfirm("¿Confirma la cancelación de la solicitud de servicio?")
        )
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            destroy("rss", this.login.token, this.id, this.login.id).then(
              (response) => {
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                response.success
                  ? this.$router.push({ name: this.page_route })
                  : console.log(response.message);
              }
            );
          }
        });
    },
    dateTimeNow() {
      var dt = new Date();

      return `${dt.getFullYear().toString().padStart(4, "0")}-${(
        dt.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${dt.getDate().toString().padStart(2, "0")} ${dt
        .getHours()
        .toString()
        .padStart(2, "0")}:${dt.getMinutes().toString().padStart(2, "0")}:${dt
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;
    },
    dischargedSubmit() {
      this.$swal
        .fire(msgConfirm(`¿Confirma el terminar la solicitud de servicio?`))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            let obj = {
              rs_id: this.data.id,
              discharged: this.dateTimeNow(),
              updated_by_id: this.login.id,
            };

            Axios.post(
              URL_API + "/rss/discharge",
              obj,
              headersToken(this.login.token)
            ).then((response) => {
              this.$swal.fire(
                msgAlert(
                  response.data.success ? "success" : "error",
                  response.data.message
                )
              );

              response.data.success
                ? this.$router.push({
                    name: this.page_route,
                  })
                : console.log(response.data.message);
            });
          }
        });
    },
    getColorBadge(counter, color) {
      if (counter !== 0) return color;
      return "grey";
    },
    getColorBadgeService(counter, pending, color) {
      if (counter !== 0) {
        if (pending === 0) {
          return color;
        } else {
          return "warning";
        }
      }

      return "grey";
    },
    rsCommentDetailDialog() {
      this.rs_comment_detail = {
        detail: "",
        response: true,
        document_file_0: null,
        rs_id: this.data.id,
      };
      this.rs_comment_details = [];

      this.rs_comment_detail_response = false;
      this.rs_comment_detail_dialog = true;
      this.rs_comment_detail_dialog_loading = true;

      Axios.get(
        URL_API + "/rss/" + this.data.id + "/comments",
        headersToken(this.login.token)
      ).then((resp) => {
        this.rs_comment_details = resp.data.data;

        if (
          this.rs_comment_details.length == 0 ||
          !this.rs_comment_details[this.rs_comment_details.length - 1].response
        ) {
          this.rs_comment_detail_response = true;
        }

        this.rs_comment_detail_dialog_loading = false;
      });
    },
    rsCommentDetailHandle() {
      if (this.$refs.rs_comment_detail_form.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma enviar comentario?`))
          .then((resp) => {
            if (resp.isConfirmed) {
              this.rs_comment_detail_dialog_loading = true;

              Axios.post(
                URL_API + "/rss/comments/detail",
                toFormData(this.rs_comment_detail),
                headersToken(this.login.token)
              ).then((resp) => {
                this.$swal.fire(
                  msgAlert(
                    resp.data.success ? "success" : "error",
                    resp.data.message
                  )
                );

                if (resp.data.success) {
                  this.getData();
                  this.rs_comment_detail_dialog = false;
                } else {
                  console.log(resp.data.message);
                }

                this.rs_comment_detail_dialog_loading = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    sinisterRepDialog() {
      this.sinister_rep = null;
      this.sinister_rep_dlg_ldg = true;
      this.sinister_rep_dlg = true;

      Axios.get(
        URL_API +
          "/insureds/sinister/report?insured_id=" +
          this.data.insured.id +
          "&icd_id=0",
        headersToken(this.login.token)
      ).then((res) => {
        this.sinister_rep = res.data.data;
        this.sinister_rep_dlg_ldg = false;
      });
    },
    commentDialog(item) {
      this.comment = item;
      this.comment_dlg = true;
    },
    rsRefoundBillDelete(id) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar esta factura?`))
        .then((res) => {
          if (res.isConfirmed) {
            this.loading = true;

            Axios.delete(
              URL_API + "/rs/refound/bills/" + id,
              headersToken(this.login.token)
            ).then((res) => {
              this.$swal.fire(
                msgAlert(
                  res.data.success ? "success" : "error",
                  res.data.message
                )
              );

              if (res.data.success) {
                this.getData();
              } else {
                console.log(res.data.message);
                this.loading = false;
              }
            });
          }
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.getData();
  },
};
</script>

<style>
.m-btn {
  margin-right: 8px !important;
}
.m-btn-badge {
  margin-right: 10px !important;
}
</style>